import React, { Component } from 'react';
import {
  booleanFormatterFactory,
  couponFormatterFactory,
  dateTimeFormatterFactory,
} from '../common/formatter/formatter';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';

import BootstrapTable from 'react-bootstrap-table-next';
import CouponListSearchForm from './common/CouponListSearchForm';
import PaginationListAndSizePerPageDropdown from './common/PaginationListAndSizePerPageDropdown';
import _ from 'underscore';
import axios from 'axios';
import config from '../config';
import moment from 'moment';
import { Link } from 'react-router-dom';

import {
  CBadge,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react';
import { Button } from 'reactstrap';

let cancel;

class CouponGroupListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duplicateConfirmModal: false,
      duplicateTargetCouponId: null,

      prevPropsPage: null,
      prevPropsSizePerPage: null,
      prevPropsSearch: null,

      //
      dataOrError: null,
      page: null,
      sizePerPage: 20,
      pagination: {},
      search: {},
      expandRow: {
        renderer: (row) => (
          <div>
            <pre>{JSON.stringify(row, null, 2)}</pre>
          </div>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
          if (isAnyExpands) {
            return <i className="fa fa-minus-square" />;
          }
          return <i className="fa fa-plus-square" />;
        },
        expandColumnRenderer: ({ expanded }) => {
          if (expanded) {
            return <i className="fa fa-minus-square" />;
          }
          return <i className="fa fa-plus-square" />;
        },
      },
      columns: [
        {
          dataField: 'id',
          text: '번호',
          headerStyle: { width: '8em', textAlign: 'center' },
          classes: 'text-center',
          // formatter: userIdFormatterFactory(row => row.id),
        },
        {
          dataField: 'name',
          text: '쿠폰',
          classes: 'text-truncate',
          formatter: couponFormatterFactory((row) => [row.id, row.name]),
        },
        {
          dataField: 'benefits',
          text: '혜택',
          headerStyle: { textAlign: 'center' },
          isDummyField: true,
          // classes: 'text-truncate',
          // formatter: userIdFormatterFactory((row) => row.user_id),
          formatter: (col, row) => {
            const voucherType = row.voucher_type;
            const { benefit } = row;

            if (voucherType === 'exchange') {
              return (
                <div>
                  <CBadge color="success">강의 교환권</CBadge>
                  <hr style={{ margin: '0.5rem 0' }} />

                  <div className="text-truncate badge-valign">
                    {benefit.course_ids.map((course_id, idx) => {
                      return (
                        <Link
                          key={idx}
                          to={`/courses/${course_id}`}
                          style={{ marginRight: '4px' }}
                        >
                          <CBadge color="primary">
                            {course_id.slice(0, 4)}
                          </CBadge>
                        </Link>
                      );
                    })}
                  </div>
                  <div>
                    수강기간: <strong>{benefit.course_duration}</strong>일
                  </div>
                </div>
              );
            }
            if (voucherType === 'discount') {
              return (
                <div>
                  <CBadge color="info">강의 할인권</CBadge>
                  <hr style={{ margin: '0.5rem 0' }} />
                  <div className="text-truncate badge-valign">
                    {benefit.course_ids.map((course_id, idx) => {
                      return (
                        <Link
                          key={idx}
                          to={`/courses/${course_id}`}
                          style={{ marginRight: '4px' }}
                        >
                          <CBadge color="primary">
                            {course_id.slice(0, 4)}
                          </CBadge>
                        </Link>
                      );
                    })}
                  </div>
                  <div>
                    할인율: <strong>{benefit.discount_percent}</strong>%
                  </div>
                </div>
              );
            }
            if (voucherType.includes('one_of')) {
              return (
                <div>
                  <CBadge color="info">강의 할인권</CBadge>
                  <hr style={{ margin: '0.5rem 0' }} />
                  <div className="text-truncate badge-valign">
                    {benefit.course_ids.map((course_id, idx) => {
                      return (
                        <Link
                          key={idx}
                          to={`/courses/${course_id}`}
                          style={{ marginRight: '4px' }}
                        >
                          <CBadge color="primary">
                            {course_id.slice(0, 4)}
                          </CBadge>
                        </Link>
                      );
                    })}
                  </div>
                  <div>
                    할인율: <strong>{benefit.discount_percent}</strong>%
                  </div>
                </div>
              );
            }
          },
        },
        {
          dataField: 'is_active',
          text: '활성 여부',
          formatter: booleanFormatterFactory((row) => row.is_active),
          classes: 'text-center',
          headerStyle: { width: '8em', textAlign: 'center' },
        },
        {
          dataField: 'created_at',
          text: '등록 시작 / 만료 일시',
          headerStyle: { width: '14em', textAlign: 'center' },
          classes: 'text-center',
          style: { textAlign: 'left' },
          formatter: (cell, row) => {
            const startAt = moment(row.start_at);
            const expiresdAt = moment(row.expires_at);
            const format = 'YYYY-MM-DD HH:mm';
            return (
              <>
                {startAt.isValid() && (
                  <div>
                    <CBadge color="primary" style={{ marginRight: '4px' }}>
                      시작
                    </CBadge>
                    <code style={{ color: '#3c4b64' }}>
                      {startAt.format(format)}
                    </code>
                  </div>
                )}
                {expiresdAt.isValid() && (
                  <div>
                    <CBadge color="primary" style={{ marginRight: '4px' }}>
                      만료
                    </CBadge>
                    <code style={{ color: '#3c4b64' }}>
                      {expiresdAt.format(format)}
                    </code>
                  </div>
                )}
              </>
            );
          },
        },
        {
          dataField: 'created_at',
          text: '생성 일시 / 사용 만료 일시',
          headerStyle: { width: '14em', textAlign: 'center' },
          style: { textAlign: 'left' },
          formatter: (cell, row) => {
            const format = 'YYYY-MM-DD HH:mm';
            const createdAt = moment(row.created_at);
            const {
              use_expire_type,
              use_expires_at,
              use_expires_in_days,
            } = row;
            let useExpires = null;

            if (use_expire_type === 'none') {
              useExpires = <>만료 없음</>;
            }
            if (use_expire_type === 'relative') {
              useExpires = (
                <>
                  등록 후 <strong>{use_expires_in_days}</strong>일 후 만료
                </>
              );
            }
            if (
              use_expire_type === 'datetime' ||
              use_expire_type === 'expires_at'
            ) {
              const useExpiresAt = moment(use_expires_at);
              useExpires = useExpiresAt.isValid() ? (
                <>{useExpiresAt.format('YYYY-MM-DD HH:mm')}</>
              ) : (
                <>-</>
              );
            }

            return (
              <div style={{ margin: '0 auto' }}>
                {createdAt.isValid() && (
                  <div>
                    <CBadge color="primary" style={{ marginRight: '4px' }}>
                      생성
                    </CBadge>
                    <code style={{ color: '#3c4b64' }}>
                      {createdAt.format(format)}
                    </code>
                  </div>
                )}
                <div>
                  <CBadge color="primary" style={{ marginRight: '4px' }}>
                    만료
                  </CBadge>
                  <code style={{ color: '#3c4b64' }}>{useExpires}</code>
                </div>
              </div>
            );
          },
        },

        {
          dataField: 'df1',
          text: 'click me',
          isDummy: true,
          formatter: (cell, row) => (
            <Button
              color="danger"
              outline
              size="sm"
              onClick={() => {
                this.setState({
                  duplicateTargetCouponId: row.id,
                });

                this.showDuplicateCouponConfirmModal();
              }}
            >
              쿠폰 복제
            </Button>
          ),
          classes: 'text-center',
          headerStyle: { width: '8em', textAlign: 'center' },
        },
      ],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.page !== prevState.prevPropsPage ||
      nextProps.sizePerPage !== prevState.prevPropsSizePerPage ||
      !_.isEqual(nextProps.search, prevState.prevPropsSearch)
    ) {
      if (typeof cancel === 'function') {
        cancel();
      }

      return {
        prevPropsPage: nextProps.page,
        prevPropsSizePerPage: nextProps.sizePerPage,
        prevPropsSearch: nextProps.search,
        //
        page: nextProps.page ? nextProps.page : 1,
        sizePerPage: nextProps.sizePerPage ? nextProps.sizePerPage : 20,
        search: { ...nextProps.search },
        dataOrError: null,
      };
    }

    return null;
  }

  componentDidMount() {
    try {
      const { page, sizePerPage, search } = this.state;
      this.loadItemList(page, sizePerPage, search);
    } catch (e) {
      console.log(e);
    }
  }

  loadItemList = async (page, sizePerPage, search) => {
    try {
      const res = await axios.get(
        `${config.API_BASE}/admin/api/coupons/coupons`,
        {
          params: {
            page,
            per_page: sizePerPage,
            ...search,
          },
        },
      );

      if (!res) {
        return;
      }

      this.setState({
        dataOrError: res.data.data,
        pagination: res.data.pagination,
      });
    } catch (e) {
      console.log(e);

      this.setState({
        dataOrError: [],
        pagination: {},
      });
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.dataOrError === null) {
      const { page, sizePerPage, search } = this.state;
      await this.loadItemList(page, sizePerPage, search);
    }
  };

  handleTableChange = async (type, { page, sizePerPage }) => {
    this.setState(() => ({
      dataOrError: null,
      page,
      sizePerPage,
    }));
  };

  handleSearchSubmit = (params) => {
    this.setState({
      search: {
        ...params,
      },
      dataOrError: null,
      page: 1,
    });
  };

  showDuplicateCouponConfirmModal = () => {
    this.setState({
      duplicateConfirmModal: true,
    });
  };

  handleDuplicateCoupon = async (couponGroupId) => {
    this.setState({
      duplicateConfirmModal: false,
    });

    const endpoint = `${config.API_BASE}/admin/api/coupons/coupons/${couponGroupId}/duplicate`;
    axios
      .post(endpoint, {})
      .then((res) => {
        this.reloadItemList();
      })
      .catch((err) => {
        alert('작업 처리 중 오류가 발생했습니다.');
      });
  };

  reloadItemList = () => {
    const { page, sizePerPage, search } = this.state;
    this.loadItemList(page, sizePerPage, search);
  };

  render() {
    const {
      dataOrError,
      sizePerPage,
      page,
      pagination: { total_count: totalSize },
      search,
      columns,
      expandRow,
      duplicateConfirmModal,
      duplicateTargetCouponId,
    } = this.state;

    const options = {
      page,
      sizePerPage,
      custom: true,
      totalSize,
    };

    return (
      <div>
        <CModal
          show={duplicateConfirmModal}
          onClose={() => {
            this.setState({ duplicateConfirmModal: false });
          }}
        >
          <CModalHeader closeButton>쿠폰 복제 확인</CModalHeader>
          <CModalBody>
            <p>쿠폰을 복제하시겠습니까?</p>
            <p>
              쿠폰 코드는 복제되지 않습니다. 쿠폰은 비활성 상태로 복제됩니다.
            </p>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="primary"
              onClick={() =>
                this.handleDuplicateCoupon(duplicateTargetCouponId)
              }
            >
              복제
            </CButton>{' '}
            <CButton
              color="secondary"
              onClick={() => {
                this.setState({ duplicateConfirmModal: false });
              }}
            >
              취소
            </CButton>
          </CModalFooter>
        </CModal>

        <div>
          <CouponListSearchForm
            handleSearchSubmit={this.handleSearchSubmit}
            {...search}
          />
          <hr />
        </div>

        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              {!this.props.hideTopPaginationArea && (
                <PaginationListAndSizePerPageDropdown
                  paginationProps={paginationProps}
                />
              )}

              <BootstrapTable
                remote
                keyField="id"
                data={dataOrError || []}
                columns={columns}
                onTableChange={this.handleTableChange}
                expandRow={expandRow}
                {...paginationTableProps}
              />

              {!this.props.hideBottomPaginationArea && (
                <PaginationListAndSizePerPageDropdown
                  paginationProps={paginationProps}
                />
              )}
            </div>
          )}
        </PaginationProvider>
      </div>
    );
  }
}

export default CouponGroupListTable;
